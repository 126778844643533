// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

 export const environment = {
    production: false,
    BASE_URL: 'https://gatewaydev.freshreview.co:19013/',
    firebaseConfig: {
        apiKey: "AIzaSyA6R72EkSfkxKHcKMIpCQq7ToRJXLv3iso",
        authDomain: "freshreview-2-dev.firebaseapp.com",
        projectId: "freshreview-2-dev",
        storageBucket: "freshreview-2-dev.appspot.com",
        messagingSenderId: "792323208737",
        appId: "1:792323208737:web:4c79b5accee290c22e1560"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

